import axios from 'axios'
import store from '@/store'
import { Message, MessageBox } from 'element-ui'
import router from '@/router'
const isDev = process.env.NODE_ENV === 'development'
console.log(process.env.NODE_ENV)
// export const baseURL = isDev ? 'https://testapi.wanxiaocar.com/api/ad' : 'https://api.wanxiaocar.com/api/ad'
// export const baseURL = 'https://testapi.wanxiaocar.com/api/ad'
// export const baseURL = 'http://192.168.2.4:5000/api/ad'
export const baseURL = 'http://39.104.77.49:5002/api/ad'
axios.interceptors.request.use(config => {
    let token = localStorage.token
    if (store.state.isPreventRequest) { // 触发阻止发送请求时要将该条件重置，否则将影响其他请求的发出
        store.commit('SET_API_PATH', {
            isPreventRequest: false,
            apiPath: '',
        })
        return Promise.reject(new Error('没有权限！'))
    }
    if (token) {
        config.headers.Token = token
        if (store.state.apiPath) config.headers.ApiPath = store.state.apiPath.toLowerCase()
    }
    return config
}, error => {
    return Promise.reject(error)
})

let timer = null
axios.interceptors.response.use(res => {
    let str = res.config.url.split('/')
    if (str[2] === 'Add' || str[2] === 'Update' || str[2] === 'Delete')
        store.commit('SET_MYSELECT_DATA', {
            postData: JSON.parse(res.config.data),
            api: str[1],
            isApi: true
        })
    if (res.data.code === 110) {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            MessageBox.confirm(`${ res.data.msg }，请重新登录！` || '请求失败！请于管理员联系！', '提示', {
                type: 'warning'
            }).then(() => {
                localStorage.clear()
                sessionStorage.clear()
                store.commit('SET_ROUTER_SYNC', [])
                store.commit('SET_ASIDE_MENU', [])
                store.commit('SET_POWER', {})
                store.commit('INIT_ROUTER_TABS_SYNC')
                router.replace({ name: 'login' }).then(() => {
                    window.location.reload(true)
                })
            })
        }, 500)
    }
    // 稍后可以做一些处理use
    return res
}, error => {
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
        Message({
            type: 'error',
            message: error.message || '请求失败！请于管理员联系！',
            duration: 3000,
            showClose: true
        })
    }, 500)
    return Promise.reject(error)
})

function Axios (options) {
    const config = {
        timeout: 15000,
        baseURL,
        ...options
    }
    config.headers = {
        'Content-Type': 'application/json'
    }
    return new Promise((resolve, reject) => {
        axios(config).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export default {
    get: ({ url, data, params }) => Axios({ method: 'GET', url, data, params }),
    post: ({ url, data, params }) => Axios({ method: 'POST', url, data, params }),
    put: ({ url, data, params }) => Axios({ method: 'PUT', url, data, params }),
    delete: ({ url, data, params }) => Axios({ method: 'DELETE', url, data, params })
}
